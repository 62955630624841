<template>
  <div class="chat gray-bcg-color">
    <c-banner :bannerData="bannerData" :src="src"></c-banner>
    <c-menu :menuData="menuData" :isChat="true"></c-menu>
    <a name="advantage" class="anchor-replace"></a>
    <div class="v-advantage c-width">
      <c-title :titleArr="advantageTitle"></c-title>
      <div class="security-wrap">
        <div
          class="security-item"
          v-for="(item, index) in securityData"
          :key="index"
        >
          <img :src="item.bcg" alt="" width="360" />
          <div class="info">
            <img class="circle-icon" :src="item.circleSrc" alt="" width="124" />
            <div class="info-title">{{ item.title }}</div>
            <img
              class="info-img"
              src="../../../assets/images/advantage_circle@2x.png"
              alt=""
              width="27"
            />
            <div class="info-text">{{ item.text1 }}</div>
            <div class="info-text">{{ item.text2 }}</div>
          </div>
        </div>
      </div>
    </div>
    <a name="solution" class="anchor-replace"></a>
    <div class="v-solution white_bcg-color">
      <c-title :titleArr="solutionTitle"></c-title>
      <c-solution :src="solutionSrc"></c-solution>
    </div>
    <a name="price" class="anchor-replace"></a>
    <div>
      <c-title :titleArr="priceTitle"></c-title>
      <c-price :priceData="priceData">
        <div slot="content">
          <div class="add-price-item">实时通信</div>
          <div class="add-price-item">智能过滤垃圾信息</div>
          <div class="add-price-item">全套Demo、SDK、开发文档等</div>
        </div>
      </c-price>
    </div>
    <a name="doc" class="anchor-replace"></a>
    <div class="document">
      <c-title
        class="doc"
        :titleArr="documentTitle"
        :isDocTitle="true"
      ></c-title>
      <div class="doc-content c-width">
        <a :href="`${docSrc}docs/show/339.html`" target="_blank">产品文档</a>
        <a :href="`${docSrc}docs/show/343.html`" target="_blank">快速开始</a>
        <a :href="`${docSrc}docs/show/352.html`" target="_blank">API</a>
        <a :href="`${docSrc}docs/show/413.html`" target="_blank">SDK</a>
        <a :href="`${docSrc}docs/show/407.html`" target="_blank">常见问题</a>
      </div>
    </div>
    <a name="recommend" class="anchor-replace"></a>
    <div>
      <c-title :titleArr="recommendTitle"></c-title>
      <c-recommend curService="chat"></c-recommend>
    </div>
  </div>
</template>

<script>
import { cRecommend, cBanner, cPrice, cMenu, cSolution } from '../components'
import { cTitle } from '@/components'
export default {
  components: {
    cRecommend,
    cBanner,
    cPrice,
    cMenu,
    cSolution,
    cTitle
  },
  data () {
    return {
      docSrc: window.urlConfig.docUrl,
      advantageTitle: ['实时、安全的通讯服务'],
      solutionTitle: ['一站式解决方案'],
      priceTitle: ['产品价格'],
      documentTitle: ['产品文档'],
      recommendTitle: ['相关推荐'],
      securityData: [
        {
          bcg: require('../../../assets/images/chat/advantage1_2@2x.png'),
          circleSrc: require('../../../assets/images/chat/advantage1_1@2x.png'),
          title: '实时互动',
          text1: '文本消息互动',
          text2: '低延时实时通讯自定义消息传输'
        },
        {
          bcg: require('../../../assets/images/chat/advantage2_2@2x.png'),
          circleSrc: require('../../../assets/images/chat/advantage2_1@2x.png'),
          title: '多终端',
          text1: '覆盖Android、iOS、PC多终端设备',
          text2: '多终端实时收发消息'
        },
        {
          bcg: require('../../../assets/images/chat/advantage3_2@2x.png'),
          circleSrc: require('../../../assets/images/chat/advantage3_1@2x.png'),
          title: '安全稳定',
          text1: '全球多数据中心和节点服务',
          text2: 'Token鉴别机制,保障消息传输安全'
        }
      ],
      isShowOneGroup: true,
      bannerData: {
        title: '聊天',
        textData: [
          '多人视频实时互动',
          '多端SDK快捷接入',
          '快捷构建多人连麦互动直播间'
        ],
        href2: `${window.urlConfig.docUrl}docs/show/411.html`
      },
      src: require('../../../assets/images/chat/chat-banner.png'),
      solutionSrc: require('../../../assets/images/chat/solution@2x.png'),
      advantageData1: [
        {
          flag: true,
          src: require('../../../assets/images/advantage2@2x.png')
        },
        {
          flag: true,
          src: require('../../../assets/images/advantage2@2x.png')
        },
        {
          flag: false,
          src: require('../../../assets/images/advantage4@2x.png')
        },
        {
          flag: false,
          src: require('../../../assets/images/advantage4@2x.png')
        }
      ],
      priceData: [
        {
          bcg: '#00EB82',
          title: '开通垃圾智能过滤功能',
          text: '月功能费=800.00元',
          adviceUrl: '',
          detailUrl: `${window.urlConfig.docUrl}docs/show/340.html`
        }
      ],
      menuData: [
        {
          src: require('../../../assets/images/subnav1@2x.png'),
          id: '#advantage',
          text: '产品优势'
        },
        {
          src: require('../../../assets/images/subnav2@2x.png'),
          id: '#solution',
          text: '解决方案'
        },
        {
          src: require('../../../assets/images/subnav3@2x.png'),
          id: '#price',
          text: '产品价格'
        },
        {
          src: require('../../../assets/images/subnav4@2x.png'),
          id: '#doc',
          text: '产品文档'
        },
        {
          src: require('../../../assets/images/subnav5@2x.png'),
          id: '#recommend',
          text: '相关推荐'
        }
      ],
      productFunData: [
        {
          className: 'interactPlat',
          title: '跨平台互动',
          desc:
            '支持Android、iOS、WEB主流平台开播、观看及互动；支持一对多，多对多的互动直播'
        },
        {
          className: 'interactLetter',
          title: '音视频通信',
          desc: '支持音视频通信，提供高质量的音视频通信效果'
        },
        {
          className: 'interactShare',
          title: '桌面分享',
          desc: '支持jssdk屏幕共享'
        },
        {
          className: 'interactRecord',
          title: '录制',
          desc:
            '支持混流视频的录制，将混流录制下来的视频存储在点播服务中，可在点播服务中对视频进行管理'
        },
        {
          className: 'interactLive',
          title: '旁路直播',
          desc: '支持直播混流，并将混流画面推送至指定的直播房间'
        },
        {
          className: 'interactjianhuang',
          title: '智能鉴黄',
          desc:
            '支持对涉黄行为进行高效自动识别，可根据用户用户管控尺度，灵活调整策略'
        },
        {
          className: 'interactPs',
          title: '美颜滤镜',
          desc: '提供移动端SDK美颜滤镜功能，支持移动sdk上的美白，磨皮'
        }
      ]
    }
  }
}
</script>

<style lang="less" scoped>
@import '../../../assets/less/varible.less';
.chat {
  .on {
    position: absolute;
    top: 0;
    opacity: 0;
  }
  .doc {
    padding-top: 44px;
  }

  .doc-content {
    display: grid;
    grid-template-columns: repeat(5, 20%);
    margin-top: 53px;

    a {
      color: #bfcddd;
      font-size: 18px;
      text-align: center;
      line-height: 32px;

      &:not(:last-child) {
        border-right: 1px solid #fff;
      }

      &:hover {
        color: #fff;
      }
    }
  }

  .add-price-item {
    width: 598px;
    font-size: 20px;
    color: #555;
    font-weight: 500;

    &:first-child {
      margin-top: 30px;
    }
  }

  .security-wrap {
    display: flex;
    justify-content: space-between;
    margin: 50px 0;
    .security-item {
      text-align: center;
      .info {
        background: #fff;
        height: 280px;
        .circle-icon {
          margin-top: 28px;
        }

        .info-title {
          margin-top: 10px;
          font-size: 24px;
          font-weight: 500;
        }
        .info-img {
          margin: 10px 0 25px;
        }

        .info-text {
          display: none;
          font-size: 16px;
        }
      }
      &:hover {
        box-shadow: @boxShadow;
        .info {
          .circle-icon {
            margin-top: -60px;
          }
          .info-text {
            display: block;
          }
        }
      }
    }
  }
}
</style>
